import {
  Helmet,
  PreStyledComponents,
  Banner,
  TickListItem,
  NavigationTiles,
  Interfaces,
  Link,
  PdfLink,
  Tile,
  Story,
  ReadMoreLink,
  IconHousingAlt,
  Box,
  Container,
  Heading,
  Row,
  Section,
  IconTick,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import React from "react"
import styled, { withTheme, ThemeProps } from "styled-components"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"
import Layout from "../../../components/disability/Layout"
import {
  DailyLivingSkillsNoIcon,
  SupportCoordinationNoIcon,
  HealthAndWellbeingNoIcon
} from "../../../components/disability/RelatedPagesContent"

const { IconWrapper, LinkButton } = PreStyledComponents

const { disabilityTheme } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    sharedSupportedLivingEasyEnglish: Interfaces.FileProps
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  }
]

const allStates = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]

const StyledList = styled.ul`
  padding-inline-start: 1.5rem;
`

const CTAButton = styled(LinkButton)`
  min-width: 100%;
`

const AccommodationType = withTheme(
  ({
    theme,
    title,
    description,
    learnMore
  }: {
    title: string
    description: string
    learnMore?: { url: string; text: string }
  } & ThemeProps<Interfaces.Theme>) => (
    <div className="flex">
      <IconWrapper
        role="presentation"
        className="IconWrapper mr3"
        size="30"
        color={theme.light}
      >
        <IconTick aria-hidden height="13" color="white" />
      </IconWrapper>
      <div>
        <Heading size={3} topMargin="none">
          {title}
        </Heading>
        <p>{description}</p>
        {learnMore && (
          <ReadMoreLink
            text={learnMore.text}
            href={learnMore.url}
            className="color-lwb-theme-darker pt3"
            rooted
          />
        )}
      </div>
    </div>
  )
)

const SupportedIndependentLivingPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    sharedSupportedLivingEasyEnglish
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Supported Independent Living | ${siteMetadata.title}`}
        description="Find the right housing so you can live more independently, while receiving the support you need."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout
        fixedCTAPhoneNumber="1800 610 699"
        fixedCTAContact={
          <DisabilityForm
            formId="disability-sil-fixed-cta"
            formTitle="Get in touch"
            formKind="disability-home-living"
          />
        }
      >
        <Banner
          image={bannerImage}
          title="Supported Independent Living"
          subTitle="Find the right housing so you can live more independently, while receiving the support you need."
          breadcrumbs={bannerBreadcrumbs}
        />
        <Container>
          <Row className="flex-wrap relative">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>What is Supported Independent Living?</Heading>
              <p>
                Supported Independent Living (SIL) is when you live in shared
                housing with other people or on your own with assisted living
                support from us.
              </p>
              <p>
                The assisted living support is different depending on your
                specific needs, but it can include help with:
              </p>
              <ul className="mt4 pl1 mb0">
                {[
                  "Moving out for the first time",
                  "Learning to live well with others in a group setting",
                  "Dealing with the responsibilities of living in your own home",
                  "Getting ready for the day, eating healthily, and keeping well",
                  "Completing household tasks"
                ].map((text, i) => (
                  <TickListItem
                    key={i}
                    text={text}
                    className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                    textClasses="pt0 pt1-ns"
                  />
                ))}
              </ul>
              <PdfLink
                className="mt4"
                to={sharedSupportedLivingEasyEnglish.edges[0].node.publicURL}
                title="Easy English – Shared and supported living at Life Without
              Barriers"
              />
            </Box>
            <Box className="relative-l w-100 w-third-l mt5 mt0-l absolute-l top-0-l right-0-l">
              <Tile ribbon>
                <div className="flex flex-column ma3 ma4-ns pv3-l justify-center items-stretch tc">
                  <div>
                    <IconWrapper
                      className="CardLink-icon IconWrapper mb3"
                      size="100"
                      color={disabilityTheme.xxxlight}
                    >
                      <IconHousingAlt
                        height="57"
                        color={disabilityTheme.medium}
                      />
                    </IconWrapper>
                  </div>
                  <h2 className="color-lwb-theme-medium">
                    Our current vacancies
                  </h2>
                  <p className="copy mt3 mb4">
                    We offer shared and Supported Independent Living across
                    Australia - find our current vacancies here.
                  </p>
                  <div>
                    {allStates.map((state, i) => (
                      <CTAButton
                        key={i}
                        className="tc fw8 mb3"
                        to={`/disability/services/supported-independent-living/vacancies/state/${state.toLowerCase()}`}
                      >
                        {state}
                      </CTAButton>
                    ))}
                  </div>
                </div>
              </Tile>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>
                  Why should I choose Supported Independent Living?
                </Heading>
                <p>
                  Supported Independent Living is a great way to live more
                  independently, while having easy access to your assisted
                  living supports.
                </p>
                <p>
                  Depending on your situation, we can either help you find
                  specialised accommodation, work with your existing housing
                  provider, or support you in your own home.
                </p>
                <p>
                  Our Disability Support Workers can be there 24/7 or they can
                  provide day-to-day assistance. All our assisted living homes
                  are designed to make you feel comfortable, safe and at home.
                </p>
                <p>
                  We’d love for you to get in touch with us to discuss what you
                  are looking for and how we can support you.
                </p>
              </Box>
              <Box className="mt5">
                <Story
                  storyType="Real Stories"
                  quoteText="This is my forever home. I'm not shifting anymore."
                  quoteFooter="Raelene, SIL Client"
                  tileFooterGrey={true}
                  youtubeVideoId="7kpO5WlXEJE"
                  youtubeVideoTitle="Real Stories - Raelene, SIL Client"
                >
                  <p>
                    Raelene found a place to call home in one of Life Without
                    Barriers’ Supported Independent Living houses in the Hunter
                    region, New South Wales.
                  </p>
                </Story>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap relative">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Heading size={2}>I’m interested – what do I do now?</Heading>
              <p>
                We offer shared and supported assisted living in most states and
                territories.
              </p>
              <div className="pv3">
                <ReadMoreLink
                  text="View our current vacancies"
                  href="/disability/services/supported-independent-living/vacancies/"
                  className="color-lwb-theme-darker"
                  rooted
                />
              </div>
              <p>
                If you are interested in our Supported Independent Living,
                please get in touch. We want to work with you to find your
                forever home. There may be upcoming vacancies that suit your
                needs.
              </p>
              <Heading size={2} topMargin="standard">
                Is this service in my NDIS plan?
              </Heading>
              <p>
                In your{" "}
                <Link className="black fw4" to="/disability/ndis">
                  SIL NDIS plan
                </Link>
                , Supported Independent Living is funded by the Core Supports
                budget, under ‘Assistance with Daily Life.’
              </p>
              <div className="mv4 br2 b-lwb-grey-light ba pr4 pr0-l">
                <StyledList>
                  {["Assistance with Daily Life (Core Supports)"].map(
                    (text, i) => (
                      <TickListItem
                        key={i}
                        text={text}
                        className={i === 0 ? "w-90-ns" : "mt4 w-90-ns"}
                        textClasses="pt0 pt1-ns"
                      />
                    )
                  )}
                </StyledList>
              </div>
              <p>
                Specialist Disability Accommodation (SDA) is for people who need
                specialist housing. It is assessed separately and funded under
                the Capital Supports Budget.
              </p>
              <div className="pv4">
                <ReadMoreLink
                  text="Read more about SDA funding"
                  href="https://www.ndis.gov.au/providers/housing-and-living-supports-and-services/specialist-disability-accommodation"
                  className="color-lwb-theme-darker"
                  external={true}
                />
              </div>
            </Box>
            <Box className="w-100 w-third-l absolute-l top-0-l right-0-l">
              <DisabilityForm
                formId="disability-sil-contact-us"
                formTitle="Get in touch"
                formKind="disability-home-living"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="w-100 w-seven-twelfths-l layout-gap">
              <Box>
                <Heading size={2}>Other types of accommodation</Heading>
                <p>
                  There are different housing terms that might appear in your
                  NDIS plan to help meet different needs. We can help with:
                </p>
                <Row className="flex-wrap">
                  <Box className="mt4">
                    <AccommodationType
                      title="Medium-term accommodation"
                      description="Temporary housing for you to live in while you wait for your permanent home or for your disability supports."
                      learnMore={{
                        url: "/disability/services/medium-term-accommodation",
                        text: "Learn more about MTA"
                      }}
                    />
                  </Box>
                  <Box className="mt4">
                    <AccommodationType
                      title="Specialist Disability Accommodation (SDA)"
                      description="For people who have very high support needs and require specific features in their home."
                      learnMore={{
                        url: "/disability/services/specialist-disability-accommodation",
                        text: "Learn more about SDA"
                      }}
                    />
                  </Box>
                  <Box className="mt4">
                    <AccommodationType
                      title="Short-term accommodation (STA)"
                      description="STA is a home away from home in an inclusive, accessible, and supportive environment."
                      learnMore={{
                        url: "/disability/services/short-term-accommodation",
                        text: "Learn more about STA"
                      }}
                    />
                  </Box>
                  <Box className="mt4">
                    <AccommodationType
                      title="Individualised living options (ILO)"
                      description="ILO is a package of supports that lets you choose where and how you live in the way that best suits your needs and wants."
                      learnMore={{
                        url: "/disability/services/individualised-living-options",
                        text: "Learn more about ILO"
                      }}
                    />
                  </Box>
                </Row>
              </Box>
            </Row>
          </Container>
        </Section>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  heading={
                    <Heading size={2} className="color-lwb-theme-medium tc">
                      You might also be interested in{" "}
                    </Heading>
                  }
                  currentPathname={location.pathname}
                  pages={[
                    DailyLivingSkillsNoIcon,
                    SupportCoordinationNoIcon,
                    HealthAndWellbeingNoIcon
                  ]}
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/sil-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    sharedSupportedLivingEasyEnglish: allFile(
      filter: { name: { eq: "LWB-EasyE-Shared-Supported-Living" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

export default SupportedIndependentLivingPage
